@import "@aws-amplify/ui-react/styles.css";

.lcoe-calculator {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  letter-spacing: 0; /* reset injected letter-spacing rule */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
ul,
ol {
  margin: 0;
}

.App * + p,
.App * + ul,
.App * + ol {
  margin-top: 1rem;
}

.type-xl-5 {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 5rem; /* 80px */
  line-height: 1.1; /* 88px */
}

.type-xl-4 {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 3.75rem; /* 60px */
  line-height: 1.1; /* 66px */
}

.type-xl-3 {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 3rem; /* 48px */
  line-height: 1.1; /* 52.8px */
}

.type-xl-3-jr {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 2.75rem; /* 44px */
  line-height: 1.1; /* 48.4px */
}

.type-xl-2 {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 2.5rem; /* 40px */
  line-height: 1.1; /* 44px */
}

.type-xl {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 2.25rem; /* 36px */
  line-height: 1.1; /* 39.6px */
}

.type-xl-bold {
  font-family: "Neue Haas Grotesk Disp Bold", sans-serif;
  font-weight: 700;
  font-size: 2.25rem; /* 36px */
  line-height: 1.1; /* 39.6px */
}

.type-xl-jr {
  font-family: "Neue Haas Grotesk Disp Roman", sans-serif;
  font-weight: 450;
  font-size: 2.125rem; /* 34px */
  line-height: 1.1; /* 37.4px */
}

.type-lg {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 1.625rem; /* 26px */
  line-height: 1.5; /* 39px */
}

.type-md {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 1.375rem; /* 22px */
  line-height: 1.5; /* 33px */
}

.type-base {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 1.125rem; /* 18px */
  line-height: 1.5; /* 27px */
}

.type-base-bold {
  font-family: "NeueHaasGroteskText Pro Bold", sans-serif;
  font-weight: 700;
  font-size: 1.125rem; /* 18px */
  line-height: 1.5; /* 27px */
}

.type-sm {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 1rem; /* 16px */
  line-height: 1.5; /* 24px */
}

.type-sm-bold {
  font-family: "NeueHaasGroteskText Pro Bold", sans-serif;
  font-weight: 700;
  font-size: 1rem; /* 16px */
  line-height: 1.5; /* 24px */
}

.type-xs {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 0.875rem; /* 14px */
  line-height: 1.5; /* 21px */
}

.type-xs-bold {
  font-family: "NeueHaasGroteskText Pro Bold", sans-serif;
  font-weight: 700;
  font-size: 0.875rem; /* 14px */
  line-height: 1.5; /* 21px */
}

.type-xs-2 {
  font-family: "NeueHaasGroteskText Pro", sans-serif;
  font-weight: 400;
  font-size: 0.75rem; /* 12px */
  line-height: 1.5; /* 18px */
}

.type-xs-2-bold {
  font-family: "NeueHaasGroteskText Pro Bold", sans-serif;
  font-weight: 700;
  font-size: 0.75rem; /* 12px */
  line-height: 1.5; /* 18px */
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .lg\:type-xl-bold {
    font-family: "Neue Haas Grotesk Disp Bold", sans-serif;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.1;
  }

  .lg\:type-base {
    font-family: "NeueHaasGroteskText Pro", sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5;
  }
}

/* Spacing utilities */
.m-0 {
  margin: 0;
}

.mb-4 {
  margin-bottom: 1rem;
}

/* Color utilities */
.color-white {
  color: #fff;
}

.color-stone {
  color: #d4d2d0;
}

.color-slate {
  color: #899b9d;
}

.color-green {
  color: #08291a;
}

.color-light-green {
  color: #526a65;
}

.bg-stone-25 {
  background-color: rgba(212, 210, 208, 0.25);
}

.bg-green {
  background-color: #08291a;
}

.contentPage {
  position: relative;
}

.contentPage__map {
  position: relative;
  height: 60vh;
  z-index: 1;
}

.contentPage__report {
  background: #fff;
}

.report {
}

.report__header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.report__header svg {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.loadingStatus {
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (min-width: 1024px) {
  .contentPage {
    display: flex;
    flex-direction: row-reverse;
  }

  .contentPage__map {
    width: 50%;
    height: auto;
  }

  .contentPage__report {
    width: 50%;
    margin-left: 1px;
  }

  .report__header {
    position: static;
    padding-top: 2.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 0;
    border: none;
  }

  .report__header svg {
    display: none;
  }
}

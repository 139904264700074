.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iconContainer {
  background: transparent;
  border: 0;
}

.iconContainer svg {
  display: block;
  max-width: 100%;
}

/* Popup */
.leaflet-popup-content-wrapper {
  min-width: 300px;
  padding: 1rem;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  font-family: "NeueHaasGroteskText Pro", sans-serif;
}

.leaflet-popup-content {
  margin: 0;
  padding: 0;
}

.leaflet-popup-content .title {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
}

.leaflet-popup-content .description {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.leaflet-popup-content .confirmButton {
  display: block;
  width: 100%;
  margin: 1.5rem 0 0 0;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  cursor: pointer;
}

.leaflet-popup-close-button {
  color: #899b9d;
}

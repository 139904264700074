.chart {
  position: relative;
  padding: 0;
}

.chart__header {
}

.chart__content {
  padding-bottom: 2rem;
}

.yAxisLabel {
  margin-bottom: 1rem;
}

.xAxisLabel {
  margin: 0;
  text-align: center;
}

.chart__render {
  height: 400px;
}

.customTooltip {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

@media (min-width: 1024px) {
  .chart {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.dataTable {
  padding: 1.5rem 0;
}

.dataTable__intro {
}

.dataTable__intro__content {
  padding: 1.5rem;
}

.hSlider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  column-gap: 1rem;
}

.hSlider__item {
  flex: 1 0 auto;
  min-width: 270px;
  max-width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .dataTable {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

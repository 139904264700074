.dataTableCard {
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dataTableCard__header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 1rem 1rem 0 1rem;
}

.dataTableCard__header__title {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.dataTableCard__header__title .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;
}

.dataTableCard__header__title svg {
  display: block;
  width: 1rem;
}

.dataTableCard__header__button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  margin-left: auto;
  align-self: start;
  cursor: pointer;
}

.dataTableCard__content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.dataTableCard__content__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.dataTableCard__content__row .row-label {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
}

.dataTableCard__content__row .row-label svg {
  display: block;
}

.dataTableCard__content__row:nth-child(odd) {
  background-color: rgba(212, 210, 208, 0.25);
}
